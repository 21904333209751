import React, { type FC, useEffect } from 'react';
import { css } from '@compiled/react';
import { useInView } from 'react-intersection-observer';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { START } from '@confluence/navdex';
import { useIsCreationDropdownEnabled } from '@confluence/content-types-utils';
import { CreateContentButtonPreloader } from '@confluence/edit-button/entry-points/preloaders';
import { useHardStorageEnforcement } from '@confluence/storage-enforcement/entry-points/HardEnforcement/useHardStorageEnforcement';
import { LoadableAfterPaint, useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';
import { UNSAFE_noExposureExp } from '@confluence/feature-experiments';

import { CreateContentMenuItem } from '../CreateContentPopover/ContextualCreateContentPopover/CreateContentMenuItem';
import { TreeCreateContentButton } from '../CreateContentPopover/ContextualCreateContentPopover/TreeCreateContentButton';
import { buildCreationLink } from '../CreateContentRouteComponent/createContentRouteUtils';

import type { CreateFromContentTreeCohorts } from './useCreateFromContentTreeEligible';
import { CREATE_FROM_CONTENT_TREE_EXPERIMENT } from './useCreateFromContentTreeEligible';

export const ContextualCreateContentPopoverLoader = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ContextualCreatePopover" */ '../CreateContentPopover'
			)
		).ContextualCreateContentPopover,
});

type TreeBottomCreateButtonProps = {
	cohort: CreateFromContentTreeCohorts;
	isCreateFromContentTreeEligible: boolean;
	spaceKey: string;
	spaceId: string;
	source: string;
};

const visibleStyle = css({
	height: 'auto',
});
const defaultStyle = css({
	height: '0',
});

export const TreeBottomCreateButton: FC<TreeBottomCreateButtonProps> = ({
	cohort,
	isCreateFromContentTreeEligible,
	spaceKey,
	spaceId,
	source,
}) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isCreationDropdownEnabled = useIsCreationDropdownEnabled();
	const isNav4Enabled = useIsNav4Enabled();
	const [buttonRef, inView] = useInView({ triggerOnce: true });
	const { enforceStorageLimit, shouldBlockCreate } = useHardStorageEnforcement({
		source,
	});

	const [, fireCreateFromContentTreeExperiment] = UNSAFE_noExposureExp(
		'confluence_create_from_content_tree_experiment',
	);

	useEffect(() => {
		if (inView) {
			fireCreateFromContentTreeExperiment();
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'exposed',
					actionSubject: 'feature',
					attributes: {
						flagKey: CREATE_FROM_CONTENT_TREE_EXPERIMENT,
						value: cohort,
					},
					source,
				},
			}).fire();
		}
	}, [inView, cohort, createAnalyticsEvent, source, fireCreateFromContentTreeExperiment]);

	const createLink = buildCreationLink({
		contentType: 'page',
		source,
		spaceKey,
	});

	const handleCreateButtonClick = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'contextualCreateButton',
				actionSubjectId: source,
				source: 'containerNavigation',
				containerType: 'space',
				containerId: spaceId,
				attributes: {
					navdexPointType: START,
				},
			},
		}).fire();
	};

	return (
		<div
			css={[defaultStyle, isCreateFromContentTreeEligible && visibleStyle]}
			ref={buttonRef}
			data-testid="page-tree-create-button"
			data-vc="page-tree-create-button"
			{...ssrPlaceholderIdProp}
		>
			{isCreateFromContentTreeEligible &&
				(isCreationDropdownEnabled ? (
					<ContextualCreateContentPopoverLoader
						spaceKey={spaceKey}
						source={source}
						isTreeItem
						offset={[0, 6]}
						placement="bottom-start"
						shouldRenderToParent
					/>
				) : (
					<CreateContentButtonPreloader editSource={source}>
						{isNav4Enabled ? (
							<CreateContentMenuItem
								onClick={enforceStorageLimit(handleCreateButtonClick)}
								href={shouldBlockCreate ? undefined : createLink}
							/>
						) : (
							<TreeCreateContentButton
								onClick={enforceStorageLimit(handleCreateButtonClick)}
								href={shouldBlockCreate ? undefined : createLink}
							/>
						)}
					</CreateContentButtonPreloader>
				))}
		</div>
	);
};
